export const TermsModal: React.FC = () => {
	const termsOfService = [
		{
			heading: "Acceptance of Terms",
			content:
				'By accessing and using FetBot (“the Service”), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you are not permitted to use the Service.',
		},
		{
			heading: "Modification of Terms",
			content:
				"FetBot reserves the right, at its sole discretion, to modify or replace these Terms at any time. If the alterations constitute a material change to the Terms, FetBot will notify you by posting an announcement on the Service.",
		},
		{
			heading: "Use of the Service",
			content:
				"You are responsible for your use of the Service and any content you post or upload. You agree not to use the Service for any illegal or unauthorized purpose.",
		},
		{
			heading: "Intellectual Property",
			content:
				"All content included on the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Service, is the property of FetBot or its suppliers and protected by copyright and other laws.",
		},
		{
			heading: "Termination",
			content:
				"FetBot may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.",
		},
		{
			heading: "Limitation of Liability",
			content:
				"In no event shall FetBot, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.",
		},
		{
			heading: "Governing Law",
			content:
				"These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.",
		},
		{
			heading: "Refund Policy",
			content:
				"All sales are final. FetBot operates on a strict no-refund policy, meaning that payments for any subscription or services provided by FetBot are non-refundable. Once you purchase a subscription or service, you are not entitled to any refunds or credits, including for partially used periods.",
		},
		{
			heading: "Contact Us",
			content:
				"If you have any questions about these Terms, please contact us at hello@fetbot.io.",
		},
	];

	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flex: 1,
				}}
			>
				<div>
					<h2>Terms of Service</h2>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "start",
							gap: "3rem",
							margin: "3rem 0",
						}}
					>
						{termsOfService.map(({ heading, content }) => (
							<Section heading={heading} content={content} key={heading} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};

const Section: React.FC<{ heading: string; content: string }> = ({
	heading,
	content,
}) => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
			alignItems: "start",
			justifyContent: "center",
			gap: ".75rem",
		}}
	>
		<span>
			<h3>
				<b>{heading}</b>
			</h3>
		</span>
		<p>{content}</p>
	</div>
);
