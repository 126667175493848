export const SupportModal: React.FC = () => (
	<>
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				flex: 1,
			}}
		>
			<div>
				<h2>Support</h2>
				<p>The best way to contact us is via this email:</p>
				<p
					style={{
						fontFamily: "'SohneMonoStrong', courier",
						color: "var(--red)",
						fontWeight: "bold",
						fontSize: "1.5rem",
						marginTop: "1rem",
					}}
				>
					hello@fetbot.io
				</p>
				<br />
				<p>
					Please include the type of request in the subject line, and give as
					much detail about your question or issue as you can.
				</p>
				<br />
				<p>
					This will help us to fix things faster. A screenshot might help too :)
				</p>
				<TroubleShooting />
			</div>
		</div>
	</>
);

const TroubleShooting: React.FC = () => (
	<>
		<h2 style={{ marginTop: "2rem" }}>Troubleshooting</h2>
		<p>How to fix common issues:</p>
		<br />

		<Question
			title="How to keep Fetbot running"
			text="Your computer must be awake and unlocked. The FetLife tab must be partially visible too, and not minimized. You can set your computer to never sleep, and FetBot will run 24/7. This is what we recommend. If you have a spare laptop or computer then even better!"
		/>
		<Question
			title="FetBot not active?"
			text="FetBot can only be active in one tab at a time. You will know it's the active one if the page meta title changes to 'FetBot', as well as the favicon and logo will change. It will try to set the active tab automatically if there are no other ones, but you can also open the extension menu (top right) and click 'Set active tab'. You may need to click a few times, or refresh the page."
		/>
		<Question
			title="Starting a new place"
			text="If you've exhausted the sub-locations in one place, you can start a new place, but you'll need to click the 'reset' button, next to 'Start FetBot', on the places page, when FetBot is active in that tab. This will reset the local storage values regarding your old places."
		/>
		<Question
			title="How to pick up where you left off"
			text="If FetBot becomes inactive, or you restart your computer, don't worry! You can simply go to any profile page, set the active tab, and it'll pick up where it left off, because everything is stored on your machine."
		/>
		<Question
			title="FetBot running but not messaging"
			text="This is likely because your preferences are too strict/narrow. We reccommend leaving the 'include roles' field blank, unless you have quite a few roles to target, or the role is common. These should be separated by a comma. The full list can be found at fetlife.com/kinktionary/roles-ujio2. We also reccommend leaving the 'last activity' field at 3 months or greater. You can adjust all these settings in the extension. Furthermore, you can check the logs of what FetBot is doing in the developer console (F12). It will say useful things like 'closed profile because not active', or 'role didnt match' etc."
		/>

		<p style={{ fontSize: "1.25rem" }}>
			<b>
				For anything else, or if you're still having issues, please contact us.
			</b>
		</p>
	</>
);

const Question: React.FC<{ title: string; text: string }> = ({
	title,
	text,
}) => (
	<>
		<b style={{ fontSize: "1.5rem" }}>{title}</b>
		<p>{text}</p>
		<br />
	</>
);
