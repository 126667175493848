const steps = [
	<span key={0}>
		<a
			href="https://buy.stripe.com/4gwaIH17VcXt7sIdQW"
			target="_blank"
			className="big-link"
			rel="noreferrer"
		>
			Get free trial here
		</a>
		{". "}
		No card required. Cancel anytime.
	</span>,
	<span key={1}>
		Go to our{" "}
		<a
			href="https://chromewebstore.google.com/detail/fetbot-the-fetlife-extens/ffkohiomkjakefniljmphamlbllcgnba"
			target="_blank"
			rel="noreferrer"
			className="big-link"
		>
			Chrome Web Store listing
		</a>{" "}
		and install the extension
	</span>,
	"Open the extension, login, enter your preferences",
	// "Go to a places page and select the places you want to target, or select all",
	"Go to a places page, e.g. fetlife.com/p/united-states/new-york, and select sub-locations",
	'Click the "Start" button',
];

export const GetStartedModal: React.FC = () => {
	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flex: 1,
				}}
			>
				<div>
					<GetStartedHeader />
					<Steps />
					<FaqHeader />
					<FAQ />
					<Tips />
				</div>
			</div>
		</>
	);
};

const Step: React.FC<{ text: string | React.ReactNode; index: number }> = ({
	text,
	index,
}) => (
	<div
		style={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		}}
	>
		<span
			style={{
				background: "var(--button-hover)",
				padding: "1rem",
				marginRight: "1rem",
				borderRadius: "50%",
				height: "1rem",
				width: "1rem",
				minHeight: "1rem",
				minWidth: "1rem",
				aspectRatio: 1,
				display: "inline-flex",
				justifyContent: "center",
				alignItems: "center",
				fontWeight: "bold",
			}}
		>
			{index + 1}
		</span>
		<p>{text}</p>
	</div>
);

const GetStartedHeader = () => (
	<>
		<h2>Get started</h2>
		<p>Ready to get up and running? Here's how.</p>
	</>
);

const FaqHeader = () => (
	<>
		<h2>How it works</h2>
		{/* <p>Read our frequ</p> */}
	</>
);

const Steps = () => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
			alignItems: "start",
			gap: "1rem",
			margin: "2rem 0",
		}}
	>
		{steps.map((step, index) => (
			// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
			<Step text={step} index={index} key={index} />
		))}
	</div>
);

const FAQ = () => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
			alignItems: "start",
			gap: "1rem",
			margin: "2rem 0",
		}}
	>
		{/* {faqs.map((step, index) => (
			// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
			<Step text={step} index={index} key={index} />
		))} */}
		<p>
			<b>
				FetBot is a sophisticated piece of software that automates behaviours
				you would normally take.
			</b>
			<br />
			<br />
			It effectively filters profiles by default, and is fully customizable.
			Just configure your settings, specify your message, and you're away!
		</p>

		<img
			src="fb-flow-new.png"
			alt="logo"
			id="fb-flow"
			style={{
				width: "100%",
				maxWidth: "1000px",
			}}
		/>

		<p>
			<b>
				IMPORTANT: We don't restrict the amount of messages you can send, but
				Fetlife does.
			</b>
			<br />
			<br />
			The limit is 8 messages every 4 hours if you're not verified, and 12
			messages if you are.
			<br />
			This means that over a 24 hour period, you will have 6 messaging windows
			that FetBot will automatically utilize for you.
			<br />
			<br />
			Therefore, you can expect to send 48 messages per day if you're not
			verified, or 72 if you are.
			<br />
			All you need to do is just watch your inbox fill up with replies!
			<br />
			<br />
			<b>
				FetBot cannot yet have a full conversations with people, so you will
				actually need some game.
			</b>
		</p>
	</div>
);

const Tips = () => (
	<>
		<small
			style={{
				marginTop: "1rem",
				// opacity: 0.8,
				alignItems: "center",
				display: "flex",
				// color: "var(--red)",
				background: "var(--button)",
				padding: "1rem",
				borderRadius: "1rem",
				width: "fit-content",
			}}
		>
			{/* <b>Note:</b> */}
			<svg
				role="graphics-symbol"
				viewBox="0 0 640 512"
				fill="currentColor"
				style={{
					height: "1.5rem",
					width: "1.5rem",
					minHeight: "1.5rem",
					minWidth: "1.5rem",
					marginRight: "1rem",
					background: "var(--button-hover)",
					padding: ".5rem",
					borderRadius: ".5rem",
				}}
			>
				<path d="M69.3 4C58.3-3.3 43.4-.3 36 10.7s-4.4 25.9 6.7 33.3l48 32c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L69.3 4zM597.3 44c11-7.4 14-22.3 6.7-33.3s-22.3-14-33.3-6.7l-48 32c-11 7.4-14 22.3-6.7 33.3s22.3 14 33.3 6.7l48-32zM24 160c-13.3 0-24 10.7-24 24s10.7 24 24 24H88c13.3 0 24-10.7 24-24s-10.7-24-24-24H24zm528 0c-13.3 0-24 10.7-24 24s10.7 24 24 24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H552zM117.3 332c11-7.4 14-22.3 6.7-33.3s-22.3-14-33.3-6.7l-48 32c-11 7.4-14 22.3-6.7 33.3s22.3 14 33.3 6.7l48-32zm432-39.9c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l48 32c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3l-48-32zM448 176c0 27.2-8.4 52.3-22.8 72.9c-3.7 5.3-8 11.3-12.7 17.7l0 0 0 0 0 0 0 0c-12.9 17.7-28.3 38.9-39.8 59.8c-10.4 19-15.7 38.8-18.3 57.5H403c2.2-12 5.9-23.7 11.8-34.5c9.9-18 22.2-34.9 34.5-51.8l0 0 0 0 0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C496 78.8 417.2 0 320 0S144 78.8 144 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0 0 0c12.3 16.8 24.6 33.7 34.5 51.8c5.9 10.8 9.6 22.5 11.8 34.5h48.6c-2.6-18.7-7.9-38.6-18.3-57.5c-11.5-20.9-26.9-42.1-39.8-59.8l0 0 0 0c-4.7-6.4-9-12.4-12.8-17.7C200.4 228.3 192 203.2 192 176c0-70.7 57.3-128 128-128s128 57.3 128 128zm-176 0c0-26.5 21.5-48 48-48c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16s16-7.2 16-16zM400 432V416H240v16c0 44.2 35.8 80 80 80s80-35.8 80-80z" />
			</svg>
			Fetbot only runs if the computer is unlocked and awake.
			<br />
			See our support page for tips on how to handle this.
		</small>

		<small
			style={{
				marginTop: "1rem",
				// opacity: 0.8,
				alignItems: "center",
				display: "flex",
				// color: "var(--red)",
				background: "var(--button)",
				padding: "1rem",
				borderRadius: "1rem",
				width: "fit-content",
			}}
		>
			{/* <b>Note:</b> */}
			<svg
				role="graphics-symbol"
				viewBox="0 0 320 512"
				fill="currentColor"
				style={{
					height: "1.5rem",
					width: "1.5rem",
					minHeight: "1.5rem",
					minWidth: "1.5rem",
					marginRight: "1rem",
					background: "var(--button-hover)",
					padding: ".5rem",
					borderRadius: ".5rem",
				}}
			>
				<path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
			</svg>
			Read our support/troubleshooting page if you experience any issues.
		</small>

		<small
			style={{
				marginTop: "1rem",
				// opacity: 0.8,
				alignItems: "center",
				display: "flex",
				// color: "var(--red)",
				background: "var(--button)",
				padding: "1rem",
				borderRadius: "1rem",
				width: "fit-content",
			}}
		>
			{/* <b>Note:</b> */}
			<svg
				role="graphics-symbol"
				viewBox="0 0 512 512"
				fill="currentColor"
				style={{
					height: "1.5rem",
					width: "1.5rem",
					minHeight: "1.5rem",
					minWidth: "1.5rem",
					marginRight: "1rem",
					background: "var(--button-hover)",
					padding: ".5rem",
					borderRadius: ".5rem",
				}}
			>
				<path d="M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5l-387 0c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480l387 0c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 96c0 13.3 10.7 24 24 24s24-10.7 24-24l0-96z" />
			</svg>
			This is beta version software, made by a lone-wolf developer. There's no
			guarantee it'll work perfectly.
			<br />
			Make sure you understand this, as we will not offer refunds.
		</small>

		<small
			style={{
				display: "block",
				marginTop: "1rem",
				opacity: 0.8,
				background: "var(--button)",
				padding: "1rem",
				borderRadius: "1rem",
				width: "fit-content",
			}}
		>
			<i style={{ alignItems: "center", display: "flex" }}>
				{/* <b>Tip:</b> */}
				<svg
					role="graphics-symbol"
					viewBox="0 0 512 512"
					fill="currentColor"
					style={{
						height: "1.5rem",
						width: "1.5rem",
						minHeight: "1.5rem",
						minWidth: "1.5rem",
						marginRight: "1rem",
						background: "var(--button-hover)",
						padding: ".5rem",
						borderRadius: ".5rem",
					}}
				>
					<path d="M256 0c53 0 96 43 96 96l0 3.6c0 15.7-12.7 28.4-28.4 28.4l-135.1 0c-15.7 0-28.4-12.7-28.4-28.4l0-3.6c0-53 43-96 96-96zM39 103c9.4-9.4 24.6-9.4 33.9 0l72.4 72.4C161.3 165.7 180 160 200 160l112 0c20 0 38.7 5.7 54.6 15.5L439 103c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72.4 72.4C410.3 225.3 416 244 416 264l72 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-72 0 0 8c0 27.2-6.8 52.8-18.8 75.3L473 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-70.9-70.9C339.3 462.5 299.7 480 256 480s-83.3-17.5-112.2-45.9L73 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l75.8-75.8C102.8 372.8 96 347.2 96 320l0-8-72 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l72 0c0-20 5.7-38.7 15.5-54.6L39 137c-9.4-9.4-9.4-24.6 0-33.9zM144 264l0 56c0 53.6 37.7 98.4 88 109.4L232 280c0-13.3 10.7-24 24-24s24 10.7 24 24l0 149.4c50.3-11 88-55.8 88-109.4l0-56c0-30.9-25.1-56-56-56l-112 0c-30.9 0-56 25.1-56 56z" />
				</svg>
				Please report any bugs, we'll get them fixed ASAP.
			</i>
		</small>

		<small
			style={{
				display: "inline-block",
				marginTop: "1rem",
				opacity: 0.8,
				background: "var(--button)",
				padding: "1rem",
				borderRadius: "1rem",
				width: "fit-content",
			}}
		>
			<i style={{ alignItems: "center", display: "flex" }}>
				{/* <b>Tip:</b> */}
				<svg
					role="graphics-symbol"
					viewBox="0 0 640 512"
					fill="currentColor"
					style={{
						height: "1.5rem",
						width: "1.5rem",
						minHeight: "1.5rem",
						minWidth: "1.5rem",
						marginRight: "1rem",
						background: "var(--button-hover)",
						padding: ".5rem",
						borderRadius: ".5rem",
					}}
				>
					<path d="M399.1 1.1c-12.7-3.9-26.1 3.1-30 15.8l-144 464c-3.9 12.7 3.1 26.1 15.8 30s26.1-3.1 30-15.8l144-464c3.9-12.7-3.1-26.1-15.8-30zm71.4 118.5c-9.1 9.7-8.6 24.9 1.1 33.9L580.9 256 471.6 358.5c-9.7 9.1-10.2 24.3-1.1 33.9s24.3 10.2 33.9 1.1l128-120c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-13-7.6-17.5l-128-120c-9.7-9.1-24.9-8.6-33.9 1.1zm-301 0c-9.1-9.7-24.3-10.2-33.9-1.1l-128 120C2.7 243 0 249.4 0 256s2.7 13 7.6 17.5l128 120c9.7 9.1 24.9 8.6 33.9-1.1s8.6-24.9-1.1-33.9L59.1 256 168.4 153.5c9.7-9.1 10.2-24.3 1.1-33.9z" />
				</svg>
				You can see useful logs in dev tools console.
			</i>
		</small>
	</>
);
