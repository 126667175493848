export const StatusModal: React.FC = () => (
	<div
		style={{
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			flex: 1,
		}}
	>
		<div>
			<h2>Status</h2>
			{/* good | degraded | offline */}
			<StatusMessage status="good" />
		</div>

		<div className="previous-incidents">
			<h3>Previous incidents:</h3>
			<small>No incidents to report</small>
		</div>
	</div>
);

const StatusMessage: React.FC<{ status: string }> = ({ status }) => {
	const getColor = (faded?: boolean) => {
		if (status === "good") {
			return faded ? "rgba(113, 192, 131, .15)" : "rgb(113, 192, 131)";
		} else if (status === "degraded") {
			return faded ? "rgba(252, 206, 81, .15)" : "rgb(252, 206, 81)";
		}
		if (status === "offline") {
			return faded ? "rgba(236, 117, 111, .15)" : "rgb(236, 117, 111)";
		}
	};
	const getText = (faded?: boolean) => {
		if (status === "good") {
			return "All systems are crushing it!";
		} else if (status === "degraded") {
			return "Investigating reports, potential issues. Watch this space for updates.";
		}
		if (status === "offline") {
			return "It's all gone wrong, please say a prayer. Watch this space for updates.";
		}
	};

	return (
		<p
			style={{
				display: "flex",
				alignItems: "center",
				height: "2rem",
				gap: ".75rem",
				backgroundColor: getColor(true),
				padding: ".75rem 1.5rem",
				borderRadius: "1rem",
				width: "fit-content",
				fontSize: ".875rem",
			}}
		>
			<svg viewBox="0 0 512 512" fill={getColor()} height="1.5rem">
				<path d="M369 175c9.4 9.4 9.4 24.6 0 33.9L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0z" />
				<path
					style={{ opacity: 0.4 }}
					d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
				/>
			</svg>
			<span style={{ color: getColor() }}>{getText()}</span>
		</p>
	);
};
