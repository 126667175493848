export const RoadmapModal: React.FC = () => {
	const steps = [
		"Build FetBot",
		"Build website",
		"Beta test it",
		"Go live (webstore and payments)",
		"Fix any bugs, and create happy customers",
		"Move to cloud hosting, and scale up",
		"Unveil Project Zeus",
		// 'Reveal secret project to double amount of results',
		// 'Reveal secret project for "FabBot" (?)',
		// "More coming soon...",
		// 'Build *optional* support for ***********.com, for $29.97 total (?)',
		// 'Build *optional* support for FabSwingers.com, for $49.97 total (?)',
	];

	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flex: 1,
				}}
			>
				<div>
					<h2>Roadmap</h2>
					<p>
						<b>What's on the horizon?</b>
					</p>
					<br />

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "start",
							gap: "1rem",
							margin: "2rem 0",
						}}
					>
						{steps.map((step, index) => (
							<Step text={step} index={index} key={step} />
						))}
					</div>

					<br />
					<small style={{ opacity: 0.8 }}>
						We will always give plenty warning and reason for any price changes.
					</small>
					<br />
					<small style={{ opacity: 0.8 }}>
						Rolling contract, cancel anytime.
					</small>
				</div>
			</div>
		</>
	);
};

const completed = 4;

const Step: React.FC<{ text: string; index: number }> = ({ text, index }) => (
	<div
		style={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		}}
	>
		<span
			style={{
				background: "var(--soft-contrast)",
				marginRight: "1rem",
				borderRadius: "50%",
				height: "2rem",
				width: "2rem",
				minHeight: "2rem",
				minWidth: "2rem",
				aspectRatio: 1,
				display: "inline-flex",
				justifyContent: "center",
				alignItems: "center",
				fontWeight: "bold",
			}}
		>
			{index < completed && (
				<svg
					viewBox="0 0 512 512"
					fill="rgb(113, 192, 131)"
					height="2rem"
					role="graphics-symbol"
				>
					<path d="M369 175c9.4 9.4 9.4 24.6 0 33.9L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0z" />
					<path
						style={{ opacity: 0.4 }}
						d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
					/>
				</svg>
			)}
		</span>
		<p
			style={{
				textDecoration: index < completed ? "line-through" : "none",
				opacity: index < completed ? 0.5 : 1,
			}}
		>
			{text}
		</p>
	</div>
);
