export const EthicsModal: React.FC = () => {
  const statement = [
    {
      heading: 'Compliance with Website Policies',
      content:
        'FetBot is designed to comply with the current policies of the website it operates on (Fetlife.com). Users are responsible for ensuring their use of FetBot remains within the terms of service of the website.',
    },
    {
      heading: 'Purpose of FetBot',
      content:
        'The primary purpose of FetBot is to significantly enhance user experience on the website. By automating certain activities, FetBot stimulates more interaction among users, fostering a more vibrant and engaging community. This increased activity not only makes the site more enjoyable for individual users but also benefits the host website. Higher levels of engagement and daily active users can lead to better community dynamics, increased user retention, and a more attractive platform for new users. In essence, FetBot serves as a catalyst for a more lively and interactive online environment, ultimately benefiting both users and the host website by enriching the overall user experience and community interaction.',
    },
    {
      heading: 'Ethical Use Agreement',
      content:
        'Users of FetBot agree to use it ethically. This includes avoiding spamming, harassment, or any form of misuse that could negatively impact other users or the website itself.',
    },
    {
      heading: 'No Guarantee of Compliance',
      content:
        'While FetBot is designed with the intent of policy compliance, users are advised that website policies may change, and it is their responsibility to stay updated with these changes.',
    },
    {
      heading: 'Liability',
      content:
        'FetBot, its developers, or affiliates will not be held liable for any misuse, policy violations, or consequences thereof by users employing the extension.',
    },
    {
      heading: 'Improvement and Feedback',
      content:
        'Users are encouraged to provide feedback for FetBot improvements and to report any potential issues or conflicts with website policies.',
    },
    {
      heading: 'Right to Modify Statement',
      content:
        'This legal and ethical statement is subject to change. Users will be notified of significant changes but are encouraged to review it periodically.',
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <div>
          <h2>Ethics</h2>
          <p>(Legality & Morality Statement)</p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '3rem',
              margin: '3rem 0',
            }}
          >
            {statement.map(({ heading, content }) => (
              <Section heading={heading} content={content} key={heading} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const Section: React.FC<{ heading: string; content: string }> = ({
  heading,
  content,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'center',
      gap: '.75rem',
    }}
  >
    <span>
      <h3>
        <b>{heading}</b>
      </h3>
    </span>
    <p>{content}</p>
  </div>
);
