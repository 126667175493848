export const Header: React.FC<{
	isScrollTop: boolean;
	modal: any;
	setModal: any;
}> = ({ isScrollTop, modal, setModal }) => (
	<>
		<span
			style={{
				position: "absolute",
				// background: 'rgba(23, 45, 67, .2)',
				height: "4rem",
				width: "100%",
				top: 0,
				left: 0,
				right: 0,
				display: "flex",
				justifyContent: "end",
				alignItems: "center",
				margin: "0 auto",
				maxWidth: "calc(160ch + 4rem)",
			}}
		>
			<HeaderLinks modal={modal} setModal={setModal} />
		</span>
		<div
			className="header-wrapper"
			style={{
				opacity: isScrollTop ? 0 : 1,
				pointerEvents: isScrollTop ? "none" : "auto",
				transition: "all .2s",
			}}
		>
			<div className="header">
				<img
					src="/icon48.png"
					alt="logo"
					style={{
						width: "2rem",
						height: "2rem",
					}}
				/>
				<div className="header-actions">
					<span>
						<HeaderLinks />
					</span>
					<a className="install-now" href="#get-started">
						{/* Add to Chrome */}
						Get started
						{/* <svg
							viewBox="0 0 512 512"
							height=".875rem"
							width=".875rem"
							style={{ minHeight: ".875rem", minWidth: ".875rem" }}
						>
							<path d="M304 24c0 13.3 10.7 24 24 24H430.1L207 271c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l223-223V184c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24H328c-13.3 0-24 10.7-24 24zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V440c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
						</svg> */}
					</a>
				</div>
			</div>
		</div>
	</>
);

const HeaderLinks = ({ modal, setModal }: any) => (
	<div
		style={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			gap: "2rem",
			margin: "0 2rem",
		}}
		className="header-links"
	>
		{/* <a href="#account"> */}
		<a
			href="https://billing.stripe.com/p/login/cN2g286vW2dW78A7ss"
			target="_blank"
			rel="noreferrer"
		>
			<svg viewBox="0 0 512 512" role="graphics-symbol">
				<path d="M192 365.8L302 256 192 146.2l0 53.8c0 13.3-10.7 24-24 24L48 224l0 64 120 0c13.3 0 24 10.7 24 24l0 53.8zM352 256c0 11.5-4.6 22.5-12.7 30.6L223.2 402.4c-8.7 8.7-20.5 13.6-32.8 13.6c-25.6 0-46.4-20.8-46.4-46.4l0-33.6-96 0c-26.5 0-48-21.5-48-48l0-64c0-26.5 21.5-48 48-48l96 0 0-33.6c0-25.6 20.8-46.4 46.4-46.4c12.3 0 24.1 4.9 32.8 13.6L339.3 225.4c8.1 8.1 12.7 19.1 12.7 30.6zm-8 176l80 0c22.1 0 40-17.9 40-40l0-272c0-22.1-17.9-40-40-40l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l80 0c48.6 0 88 39.4 88 88l0 272c0 48.6-39.4 88-88 88l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
			</svg>
			<span className="tooltip">Account</span>
		</a>
		<a href="#support">
			<svg viewBox="0 0 512 512" role="graphics-symbol">
				<path d="M48 256C48 141.1 141.1 48 256 48s208 93.1 208 208l0 144.1c0 22.1-17.9 40-40 40L313.6 440c-8.3-14.4-23.8-24-41.6-24l-32 0c-26.5 0-48 21.5-48 48s21.5 48 48 48l32 0c17.8 0 33.3-9.7 41.6-24l110.4 .1c48.6 0 88.1-39.4 88.1-88L512 256C512 114.6 397.4 0 256 0S0 114.6 0 256l0 40c0 13.3 10.7 24 24 24s24-10.7 24-24l0-40zm112-32l0 112c-17.7 0-32-14.3-32-32l0-48c0-17.7 14.3-32 32-32zM80 256l0 48c0 44.2 35.8 80 80 80l16 0c17.7 0 32-14.3 32-32l0-144c0-17.7-14.3-32-32-32l-16 0c-44.2 0-80 35.8-80 80zm272-32c17.7 0 32 14.3 32 32l0 48c0 17.7-14.3 32-32 32l0-112zm80 32c0-44.2-35.8-80-80-80l-16 0c-17.7 0-32 14.3-32 32l0 144c0 17.7 14.3 32 32 32l16 0c44.2 0 80-35.8 80-80l0-48z" />
			</svg>
			<span className="tooltip">Support</span>
		</a>
	</div>
);
