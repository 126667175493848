import "./App.css";
import { Banner } from "./components/Banner";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Card } from "./components/Card";
// import { MiniCards } from './components/MniCards';
import { useState, useEffect } from "react";

const App = () => {
	const [isScrollTop, setScrollTop] = useState(true);
	const [modal, setModal] = useState({
		isOpen: false,
		content: null,
	});

	useEffect(() => {
		const onScroll = () => {
			const cta = document
				.querySelector(".hero-cta-wrapper a")
				?.getBoundingClientRect();

			if (!cta) return;

			console.log("ctaOffset", cta?.top);

			setScrollTop(!(cta?.top < 0 - cta?.height));
		};

		window.addEventListener("scroll", onScroll);

		console.log("window.scrollY === 0", window.scrollY === 0);
		console.log("isScrollTop", isScrollTop);
		return () => window.removeEventListener("scroll", onScroll);
	}, [isScrollTop]);

	return (
		<div className="App">
			<Banner />
			<Header isScrollTop={isScrollTop} modal={modal} setModal={setModal} />

			<div className="content">
				<Card flavour="CTA" isScrollTop={isScrollTop} />

				<span style={{ display: "flex", gap: "2rem" }} className="new-card-row">
					<Card flavour="Kinksters" isScrollTop={isScrollTop} />
					<Card flavour="Closing" isScrollTop={isScrollTop} />
				</span>

				<span style={{ display: "flex", gap: "2rem" }} className="new-card-row">
					<Card flavour="Message" isScrollTop={isScrollTop} />
					<Card flavour="Support" isScrollTop={isScrollTop} />
				</span>

				{/* <Card flavour="Get Started" isScrollTop={isScrollTop} /> */}

				{/* <MiniCards /> */}
			</div>

			<Footer modal={modal} setModal={setModal} />
		</div>
	);
};

export default App;
