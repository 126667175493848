export const PrivacyModal: React.FC = () => {
  const privacyPolicy = [
    {
      heading: 'Introduction',
      content:
        'FetBot is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by FetBot.',
    },
    {
      heading: 'Information Collection',
      content:
        'We collect information that you provide directly to us, such as when you create an account, update your profile, or communicate with us. This may include personal information like your name, email address, and other contact details.',
    },
    {
      heading: 'Use of Information',
      content:
        'The information we collect is used to provide, maintain, and improve our services, to communicate with you, and to personalize your experience with FetBot.',
    },
    {
      heading: 'Sharing of Information',
      content:
        'FetBot does not share your personal information with third parties, except as necessary to provide the service, comply with the law, or protect our rights.',
    },
    {
      heading: 'Data Security',
      content:
        'We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.',
    },
    {
      heading: 'Your Privacy Rights',
      content:
        'You have the right to access, update, or delete the personal information we hold about you. You can exercise these rights by contacting us directly.',
    },
    {
      heading: 'Changes to Privacy Policy',
      content:
        'We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.',
    },
    {
      heading: 'Contact Us',
      content:
        'If you have any questions about this Privacy Policy, please contact us at hello@fetbot.io.',
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <div>
          <h2>Privacy Policy</h2>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '3rem',
              margin: '3rem 0',
            }}
          >
            {privacyPolicy.map(({ heading, content }) => (
              <Section heading={heading} content={content} key={heading} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const Section: React.FC<{ heading: string; content: string }> = ({
  heading,
  content,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'center',
      gap: '.75rem',
    }}
  >
    <span>
      <h3>
        <b>{heading}</b>
      </h3>
    </span>
    <p>{content}</p>
  </div>
);
