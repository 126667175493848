export const Modal: React.FC<{
  modal: any;
  setModal: (prev: any) => void;
}> = ({ modal, setModal }) => (
  <div
    className="modal-background"
    style={{
      opacity: modal.isOpen ? 1 : 0,
      pointerEvents: modal.isOpen ? 'all' : 'none',
      backdropFilter: modal.isOpen
        ? 'blur(2px) saturate(108%)'
        : 'blur(0) saturate(100%)',
    }}
  >
    <div
      className="modal"
      style={{
        opacity: modal.isOpen ? 1 : 0,
        pointerEvents: modal.isOpen ? 'all' : 'none',
        top: modal.isOpen ? '0' : '100%',
      }}
    >
      <button
        className="close-modal"
        onClick={(prev) => {
          window.history.pushState(null, '', '/');
          setModal({ ...prev, isOpen: false });
        }}
        style={{ opacity: modal.isOpen ? 1 : 0 }}
        aria-label="Close modal"
      >
        <svg viewBox="0 0 384 512">
          <path
            style={{ opacity: 0.4 }}
            d="M297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6z"
          />
        </svg>
      </button>

      <div className="modal-content">{modal.content}</div>
    </div>
  </div>
);
